<template>

<app-wrapper :content="true" class="head">

	<div class="head-image" v-bgimage="$state.page.image" />

	<div class="head-info">

		<h2>{{ $state.page.name }}</h2>

		<h3>{{ $state.page.role }}</h3>
		<h4 v-if="$state.page.subrole">{{ $state.page.subrole }}</h4>

		<div class="head-info-list">

			<div class="head-info-list-item is-email" v-if="!window.is.mobile"><a :href="'mailto:' + $state.page.email">{{ $state.page.email }}</a></div>
			<div class="head-info-list-item is-email" v-if="window.is.mobile"><a :href="'mailto:' + $state.page.email">{{ emailPreview }}</a></div>
			<div class="head-info-list-item is-linkedin" v-if="$state.page.linkedin"><i class="fa fa-linkedin"></i><a :href="$state.page.linkedin">Connect with me on LinkedIn</a></div>
			<div class="head-info-list-item is-phone"><a :href="'tel:' + $state.page.phone">{{ $state.page.phone }}</a></div>
			<div class="head-info-list-item is-qualifications" v-html="$state.page.qualifications" />

		</div>

	</div>

</app-wrapper>

</template>

<script>

export default {

	computed: {

		emailPreview: function() {

			return this.$state.page.email.split('@')[0]

		}

	}

}

</script>

<style scoped>

.head {
	display: grid;
	grid-template-columns: 351px auto;
	grid-gap: 64px;
}

.is-device .head {
	grid-gap: 0px;
	grid-template-columns: 1fr;
}

.head-image {
	width: 350px;
	height: 350px;
	margin-top: -130px;
	background-position: 50% 50%;
	background-size: contain;
}

.is-tablet .head-image {
	position: absolute;
	left: 20px;
	top: -80px;
	width: 215px;
	height: 215px;
	margin-top: 0px;
}

.is-mobile .head-image {
	position: absolute;
	left: 50%;
	top: -65px;
	width: 130px;
	height: 130px;
	margin-top: 0px;
	margin-left: -65px;
}

.head-info {
	padding: 48px 0px;
}

.is-tablet .head-info {
	padding: 24px 0px;
}

.is-mobile .head-info {
	padding: 80px 0px 32px 0px;
}

.head-info h2 {
	color: #1A2046;
	font-size: 56px;
	line-height: 70px;
	font-weight: 700;
}

.is-tablet .head-info h2 {
	font-size: 36px;
	padding-left: 240px;
	line-height: 40px;
}

.is-mobile .head-info h2 {
	font-size: 24px;
	text-align: center;
	line-height: 40px;
}

.head-info h3 {
	color: #1A2046;
	font-size: 26px;
	line-height: 28px;
	font-weight: 400;
    color: #FF2D07;
}

.is-tablet .head-info h3 {
	font-size: 20px;
	padding-left: 240px;
	margin-top: 5px;
}

.is-mobile .head-info h3 {
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}

.head-info h4 {
	color: #1A2046;
	font-size: 26px;
	line-height: 28px;
	font-weight: 400;
	margin-top: 5px;
}

.is-tablet .head-info h4 {
	font-size: 20px;
	padding-left: 240px;
	margin-top: 5px;
}

.is-mobile .head-info h4 {
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}

.head-info-list {
	margin-top: 40px;
}

.is-tablet .head-info-list {
	margin-top: 40px;
}

.is-mobile .head-info-list {
	margin-top: 32px;
}

.head-info-list-item {
	color: #45464B;
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 10px;
	padding: 8px 0px 8px 54px;
	background-position: 50% 50%;
	background-size: auto 120px;
}

.is-device .head-info-list-item {
	font-size: 14px;
}

.head-info-list-item:before {
	content: '';
	display: block;
	width: 44px;
	height: 44px;
	position: absolute;
	left: 0px;
	top: 0px;
	background-size: 44px 44px;
}

.head-info-list-item.is-email:before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAXVBMVEUAAABIXMdIXMdHWshHXMhHXMdIXMdIXMdHXcdIW8dIW8hIXMdIW8hHW8VJXMdJXcZIXMf///92hdV1hNXR1vGkruP09fu7wupqe9L09fzGze6vuOeYpOCBj9lfcM57IqXVAAAAEHRSTlMA3sYxY/anioTtuJt4c1s/p7rgsAAAAgVJREFUWMOs1G2ugyAUhGE4FEXrxzQ3WtPuf5/XP+bEaBkU3gU8ISNiEnJDqMQ2HvCNlSoMzuTnutbjkG87l6XWFj+z9V37ISDJ4wbbWyRk+6unVZbRV049Ci4kY6pb42J1EusElxOXsC5uRZcOuFmIu0/c7hlzK2RUETdDJjsUXyMgu1DunvFb51Akd4AFRZLk9yHz3RhRrLHsEJqQG1HmZlgUzKrbo2h92QNrliycv7KgcEJ+5uWPtOBHLvrTfV+0L86ro59ufvFwno0u8UootkVH4M900ofA3Qq3BJ4XHFpmArcr7KOwynuXwF4nPoffm3x03zEYzgxRGJt8dBGFBxPi8CYf3TgcTEVglfcugSsjBFZ55zJYjKWwyupS2JqGwSqry+HGeAarPE3qMtgbEFjlNXUZjP/mzCAHQhAIgkQDuheDBA4k6v+fuXvjtD1s1or0A+qgJDNTbYEbuXH7wL4PHEvdP6kl9oG99fNarqOU44qd4Jf13ESM5zYx4MkFBhzcyoBXtzHgzc0MeDZGUxZRYK+Gad7N5K/DVI3/ZIOTGP/iI59VY+spPrHejpOMtSEv8fYsbY29NzO1eGOnAnXcYOcYdUBiJy91pGNagRIhlLrBZBOlxyihxypILU0H0rGBEtOUSqfkP1VXQAULVQk9X2L9UruNUxT2VJvjlbH/1MdvUVN+dcQCiMQAAAAASUVORK5CYII=');
}

.head-info-list-item.is-phone:before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAS1BMVEUAAABIXMdHWshIXMdHXMhHW8dIXMdIXMdHXcdIXcdIXMZIW8hIXMdIW8hHW8VIXMZJXcZIXMf///9fcM709fzGze7p6/jo6/jGzO1bgxbZAAAAEXRSTlMA3jHEX/CnioT4yribeHNnP9HkXvAAAAGoSURBVFjDtNVBroMwDEVRO05CgEJ5QEX3v9KvP2vVpg4hvkMGR8jChgpyPkXh0AN9YInJO7qem8aAj8I4uUtqx8jGXa3tBUriK9iZURDPJ9k7ozC+U3mL4ESylLodTtYVsW7A6Qanux5Vec1NqCz9dm+o7vbLjbhQNHKBqMyh+TQSLpfafWf6V+fQJPcBD2jSUHEfqu7GgmYtb7CgWfJ219Gw18vPaBi//N+QbXse65eO54Zsc8ELb/uaac/LXLBzjzXbQ98/QbY9Dx/IJvoy/wu55+pid5Wwun7cHmZlEgqszGKygCciGi3gkYiCBRyIHCxgOPI2sKdkAyeKNnAksYGF2AZmCjZwoN4G7gk2MP6as2MbAEIYhqIV11HC/qPeAiCD0BN0VCmAJPY3K8yugj0e+26sQVhLsyHkxmYe9LNzsgxFFC6zZZoL52VaReHKBIuSWEwUMhl7Irx7EN7EKkBzk+1Y68N7aMGOEQPJLK8y6QwrKBCi0A2DTQqPOaDnEWSGptdx7KfAtELpCv6ruAIFLCoSuh9i7cRu7wSFK9Hme2HsSXz8A+EskoX8iHBPAAAAAElFTkSuQmCC');
}

.head-info-list-item.is-qualifications:before {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAe1BMVEUAAABIXMdHXMhGWshJXMdIXMdIXMdIXMdHXcdIXcdHXMZIW8dIXMZIW8hIXMdIW8hHW8VJXMdJXcZKW8ZIXMf///9qe9Ld4PXS1/FfcM67wuqNmdz09fxTZsvGze7p6/jGzO2YpOCBj9n09fuvuOekruOMmdyYo992hdUVaQnRAAAAFHRSTlMAxGMz4dunioT48+3KuJt4c1s/LV60LBgAAAKFSURBVFjDzZndeqIwEIaTIqigVfQjgECl/nXv/wqXsEt/YJsZCHm270GrHrxPHJKJMyMYHONVIP1oDawjXwar+Cjs8XbbEAPC7c6zsi59fIu/nOp+kiCQTxO0+wUYLPZjV9tpafWYVR8kRiAPXO8SI1nytsIGo9l4jOhiEmSkV5jIyux9xmSeTd4AFgSE19LMj4N9NFawZsXfZ/a7zsMsDE/KBrOwYecHy7xxwGx8zXUSsyEZO8J+ZywwI4sP7x4k6palSZJmdwWSPX/Bj3PyzvlBLpkb4TxLvpDlzChLGPl1TXpcFWtjeIT3j+xUKEAVL0kLYfYYhy5v1/uSv79v1deccfx8mGjj+4pPvLZxhgmfjkTRefvmCxmLHUycdRzQQ0fjDBO7RryFAZU05IO4k89v24hDGLjp/QD8a8l3GAiFOJKPrsCAgnx8RxHDRNp952GEUpiIibs5aTB8brqvAzfigEgU5fehKIl04bt5eL6IMHG73WAiEmu4OCBYC4A80jV61OSRBgQwLQkVlHgNM6m2vOETb3Ta1KGIYEaV2lN/JPpavy8VzETCB8ElaakfClCPOmm5gMAXEqS5THqUpBeSU3eovliBJGAVCH0xq2iImeJ7VjYxyO5McSyOTPHwNZHoRehCHOrL1IV4q69/triq2GJ9/XtccZWmFVfsiQafJ670D++KJ/aZlZiWaa8267/MmsxjibW3+8etT+XcJ092pQJTfDoxxU/c4uavFzixxAt2OdZ5OzPM7F0VkM5KXldFurO2gqtGiKvWjbNmk6v2mLOGnvsWpPumqb05cNeYdt9Kd9/8tx9XOBqwuBoJ/f8h1pix288ZFHJGmz9vGGszPv4N/XLdUqhti5wAAAAASUVORK5CYII=');
}

.head-info-list-item.is-linkedin i {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background-color: #485CC7;
	position: absolute;
	left: 0px;
	top: 0px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
}

.head-info-list-item:last-child {
	margin-bottom: 0px;
}

</style>
